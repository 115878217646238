export default {
  ACCESS_TOKEN: '7d60c652-617b-4904-94d0-d1e9617fd249',
  API_VERSION: process.env.REACT_APP_API_VERSION,
  INDUSTRY_TYPE: process.env.REACT_APP_INDUSTRY_TYPE,
  APP: process.env.REACT_APP_APP_TYPE,
  HOST_URL: process.env.REACT_APP_HOST_URL,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
  POLICY_CLIENTID: process.env.REACT_APP_POLICY_CLIENTID,
  POLICY_CLIENTSECRET:process.env.REACT_APP_POLICY_CLIENTSECRET,
  // AUTH_URL: '',
  AUTH_CODE: '',
  REFRESH_TOKEN: '',
  ENVIROMENTUL:
    process.env.REACT_APP_BUILD == 'dev' || process.env.REACT_APP_BUILD == 'qa'
      ? 'dev'
      : 'prod',
  DEFAULT_COUNTRY_CODE: 'US',
  PARTNERCODE: process.env.REACT_APP_PARTNERCODE,
};
