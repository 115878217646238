export default {
  Login: 'account-members/login',
  SignUp: 'account-members/',
  sendVerificationCode: {
    Signature: '/customers/initiate-signup?phone=',
    phone: '',

    set(phone) {
      this.phone = phone;
    },

    get get() {
      return this.Signature + this.phone;
    },
  },
  verifyCode: {
    phone: '',
    code: '',
    get get() {
      return (
        '/customers/validate-signup?phone=' + this.phone + '&code=' + this.code
      );
    },
  },
  checkPhoneNumber: {
    Signature: '/customers/phone/check?phone=',
    phone: null,
    get get() {
      return this.Signature + this.phone;
    },
  },
  signinCode: {
    Signature: '/customers/signin-code?phone=',
    phone: null,
    get get() {
      return this.Signature + this.phone;
    },
  },
  signin: {
    Signature: '/customers/signin',
    get get() {
      return this.Signature;
    },
  },
  signupCode: {
    Signature: '/customers/signup-code?phone=',
    phone: null,
    get get() {
      return this.Signature + this.phone;
    },
  },
  signupValidate: {
    Signature: '/customers/signup?phone=',
    phone: null,
    code: null,
    get get() {
      return this.Signature + this.phone + '&code=' + this.code;
    },
  },
  refreshToken: {
    Signature: '/customers/auth/refresh',
    get get() {
      return this.Signature;
    },
  },
  authApi: {
    Signature: '/customers/auth',
    driverId: '',

    set(driverId) {
      this.driverId = driverId;
    },

    get get() {
      return this.Signature;
    },
  },
};
