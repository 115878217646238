export default {
  getVehicleByVIN: {
    Signature: '/coverage/vin/',
    vin: '',

    set(vin) {
      this.vin = vin;
    },

    get get() {
      return this.Signature + this.vin;
    },
  },

  allVehicles: {
    Signature: '/customer-account-members/',
    memberId: '',

    setParam(memberId) {
      this.memberId = memberId;
    },
    get getRQURL() {
      return this.Signature + this.memberId + '/vehicles';
    },
  },
  getVehicleFromVin: {
    //Signature: '/customer-account-members/',
    Signature: '/customers/',
    memberId: '',
    vin: '',

    setParam(memberId, vin) {
      this.memberId = memberId;
      this.vin = vin;
    },
    get getRQURL() {
      return this.Signature + this.memberId + '/vehicle/vin/' + this.vin;
    },
  },
  createVehicle: {
    //Signature: '/customer-account-members/',
    Signature: '/customers/',
    memberId: '',

    setParam(memberId) {
      this.memberId = memberId;
    },
    get getRQURL() {
      return this.Signature + this.memberId + '/vehicles';
    },
  },
  findByToken: {
    Signature: '/customers/find-by-token?requestToken=',
    auth: '',

    setParam(auth) {
      this.auth = auth;
    },
    get getRQURL() {
      return this.Signature + this.auth;
    },
  },
  verifyTransactionCode: {
    Signature: '/reunite-jobs/verify/token/',
    code: '',

    setParam(code) {
      this.code = code;
    },
    get getRQURL() {
      return this.Signature + this.code;
    },
  },
  getCustomerTokenFromTransactionCode: {
    Signature: '/reunite-jobs/token/customer/',
    code: '',

    setParam(code) {
      this.code = code;
    },
    get getRqURL() {
      return this.Signature + this.code;
    },
  },

  getVehicleModel: {
    Signature: '/vehicles/models',
    make: '',

    set(make) {
      this.make = make;
    },
    get get() {
      return this.Signature + '?' + 'make' + '=' + this.make;
    },
  },

  getVehicleByRego: {
    Signature: '/coverage/license-plate/',
    rego: '',

    set(rego) {
      this.rego = rego;
    },

    get get() {
      return this.Signature + this.rego;
    },
  },

  setDefaultVehicle: {
    Signature: '/customer-account-members/',
    customerId: null,
    vehicleId: null,
    flag: true,

    setParam(customerId, vehicleId) {
      this.customerId = customerId;
      this.vehicleId = vehicleId;
    },

    get get() {
      return (
        this.Signature +
        this.customerId +
        '/vehicles/' +
        this.vehicleId +
        '/default?isdefault=' +
        this.flag
      );
    },
  },
  getVehicleMakes: {
    Signature: '/vehicle-configs/types/',
    type: '',
    get get() {
      return this.Signature + this.type + '/makes?size=125';
    },
  },
  getVehicleModels: {
    Signature: '/vehicle-configs/',
    make: '',
    type: '',
    get get() {
      if (this.type != '') {
        return (
          this.Signature +
          'types/' +
          this.type +
          '/models?make=' +
          this.make +
          '&size=125'
        );
      } else {
        return this.Signature + '/models?make=' + this.make + '&size=125';
      }
    },
  },
  getVehicleColors: {
    Signature: '/vehicle-configs/colors?limit=50',
    get get() {
      return this.Signature;
    },
  },
  getVehicleYears: {
    Signature: '/vehicle-configs/years',
    make: '',
    model: '',
    get get() {
      if (this.make != '' && this.model != '') {
        return (
          this.Signature +
          '?make=' +
          this.make +
          '&model' +
          this.model +
          '&limit=50'
        );
      } else {
        return this.Signature + '?limit=50';
      }
    },
  },
  getSystemConfig: {
    Signature: '/system-configs/keys?',
    env: '',
    apiVersion: '',

    setParam(env, apiVersion) {
      this.env = env;
      this.apiVersion = apiVersion;
    },
    get getRQURL() {
      return this.Signature + 'env=' + this.env + '&v=' + this.apiVersion;
    },
  },
  getActiveJobs: {
    Signature: '/customer-accounts',
    accountId: null,

    setParam(accountId) {
      this.accountId = accountId;
    },

    get getRQURL() {
      return this.Signature + '/' + this.accountId + '/jobs?active=1';
    },
  },
  cancelReasons: {
    Signature: '/customers/cancel/reasons',
    v: '',
    env: '',
    industry: '',
    device: '',
    os: '',
    app: '',
    partnerId: '',

    set(v, env, industry, device, os, app, partnerId) {
      this.v = v;
      this.env = env;
      this.industry = industry;
      this.device = device;
      this.os = os;
      this.app = app;
      this.partnerId = partnerId;
    },

    get get() {
      return (
        this.Signature +
        '?' +
        'v' +
        '=' +
        this.v +
        '&' +
        'env' +
        '=' +
        this.env +
        '&' +
        'industry' +
        '=' +
        this.industry +
        '&' +
        'device' +
        '=' +
        this.device +
        '&' +
        'os' +
        '=' +
        this.os +
        '&' +
        'app' +
        '=' +
        this.app
      );
    },
  },
  getPolicyDetail: {
    Signature: '/policy?policy=',
    policyNumber: null,
    partnerId: null,
    setParams(policy, partnerId) {
      this.policyNumber = policy;
      this.partnerId = partnerId;
    },
    get get() {
      return this.Signature + this.policyNumber + '&partner=' + this.partnerId;
    },
  },
};
