import * as types from '../../actionTypes';
import { UrgentlyAPIs } from '../../shared/';
import APIConfigs from '../../configs/AppConfigs';
import { ConfigAPI } from '../../shared/NetworkLayer/UrgentlyAPIs/APISignature';
import global from '../../utils/global';
import {
  QuestionAPI,
  VehicleAPI,
  AccountsAPI,
  LoginAPI,
} from '../../shared/NetworkLayer/UrgentlyAPIs/APISignature';
import notification from '../../shared/Utils/notification';
import translate from '../../shared/Localization/translate';
// import Swal from 'sweetalert2';

export const c_questions = [
  {
    answer: false,
    answers: { select_make: 'Select Make' },
    questionId: '1',
    text: 'Great. What is the Make?',
    title: 'Make',
    placeHolder: 'Enter Make',
  },
  {
    answer: false,
    answers: { select_model: 'Select Model' },
    questionId: '2',
    text: 'Ok. What is the Model?',
    title: 'Model',
    placeHolder: 'Enter Model',
  },
  {
    answer: false,
    answers: { select_color: 'Select Color' },
    questionId: '3',
    text: 'What is the color of your vehicle?',
    title: 'Color',
    placeHolder: 'Enter Color',
  },
  {
    answer: false,
    answers: { select_year: 'Select Year' },
    questionId: '4',
    text: 'What is the year of your vehicle?',
    title: 'Year',
    placeHolder: 'Enter Year',
  },
];

export const vehicleClassEnum = {
  motorcycle: 'MOTORCYCLE',
  light_duty: 'LIGHT',
  medium_duty: 'MEDIUM',
  heavy_duty: 'HEAVY',
};

const error = {
  type: 'webCustVehicleDetermination',
  receiveDTO: {
    error: true,
    errorMessage: 'An error has occurred.',
    cancel: false,
  },
};
const safeco_cp_questions = [
  {
    answer: false,
    answers: { confirm_name: '' },
    questionId: '1',
    // text: translate.WHAT_IS_YOUR_FIRST_NAME,
    text: translate.CONFIRM_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { enter_first_name: '' },
    questionId: '2',
    text: translate.WHAT_IS_YOUR_FIRST_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { enter_last_name: '' },
    questionId: '3',
    text: translate.WHAT_IS_YOUR_LAST_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { 'not_listed': `${translate.NOT_LISTED}` },
    questionId: '4',
    text: translate.CONFIRM_NUMBER_TEXT,
    title: '',
  },
  {
    answer: false,
    answers: { select_custom_number: 'Select Model' },
    questionId: '5',
    // text: translate.WHAT_IS_YOUR_CALLBACK_NUMBER,
    text: translate.SERVICE_NUMBER_UPDATE,
    title: '',
  },
  {
    answer: false,
    answers: { '6_YES': `${translate.YES}`, '6_NO': `${translate.NO}` },
    questionId: '6',
    text: translate.WANT_UPDATES,
    title: '',
  },
];
const bmwmini_cp_questions = [
  {
    answer: false,
    answers: { confirm_name: '' },
    questionId: '1',
    // text: translate.WHAT_IS_YOUR_FIRST_NAME,
    text: translate.CONFIRM_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { enter_first_name: '' },
    questionId: '2',
    text: translate.WHAT_IS_YOUR_FIRST_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { enter_last_name: '' },
    questionId: '3',
    text: translate.LAST_NAME_BMWMINI,
    title: '',
  },
  {
    answer: false,
    answers: { 'not_listed': `${translate.NOT_LISTED}` },
    questionId: '4',
    text: translate.CONFIRM_NUMBER_TEXT,
    title: '',
  },
  {
    answer: false,
    answers: { select_custom_number: 'Select Model' },
    questionId: '5',
    // text: translate.WHAT_IS_YOUR_CALLBACK_NUMBER,
    text: translate.SERVICE_NUMBER_UPDATE,
    title: '',
  },
  {
    answer: false,
    answers: { '6_YES': `${translate.YES}`, '6_NO': `${translate.NO}` },
    questionId: '6',
    text: translate.WANT_UPDATES,
    title: '',
  },
];
const donlen_cp_questions = [
  {
    answer: false,
    answers: { confirm_name: '' },
    questionId: '1',
    // text: translate.WHAT_IS_YOUR_FIRST_NAME,
    text: translate.CONFIRM_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { enter_first_name: '' },
    questionId: '2',
    text: translate.FULL_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { 'not_listed': `${translate.NOT_LISTED}` },
    questionId: '3',
    text: translate.CONFIRM_NUMBER_TEXT,
    title: '',
  },
  {
    answer: false,
    answers: { select_custom_number: 'Select Model' },
    questionId: '4',
    // text: translate.WHAT_IS_YOUR_CALLBACK_NUMBER,
    text: translate.SERVICE_NUMBER_UPDATE,
    title: '',
  },
  {
    answer: false,
    answers: { '5_YES': `${translate.YES}`, '5_NO': `${translate.NO}` },
    questionId: '5',
    text: translate.WANT_UPDATES,
    title: '',
  },
];

const amfam_cp_questions = [
  {
    answer: false,
    answers: { confirm_name: '' },
    questionId: '1',
    // text: translate.WHAT_IS_YOUR_FIRST_NAME,
    text: translate.CONFIRM_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { enter_first_name: '' },
    questionId: '2',
    text: translate.WHAT_IS_YOUR_FIRST_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { enter_last_name: '' },
    questionId: '3',
    text: translate.WHAT_IS_YOUR_LAST_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { 'not_listed': `${translate.NUMBER_NOT_LISTED}` },
    questionId: '4',
    text: translate.CONFIRM_NUMBER_TEXT,
    title: '',
  },
  {
    answer: false,
    answers: { select_custom_number: 'Select Model' },
    questionId: '5',
    // text: translate.WHAT_IS_YOUR_CALLBACK_NUMBER,
    text: translate.SERVICE_NUMBER_UPDATE,
    title: '',
  },
  {
    answer: false,
    answers: { '6_YES': `${translate.YES}`, '6_NO': `${translate.NO}` },
    questionId: '6',
    text: translate.WANT_UPDATES,
    title: '',
  },
];
const cp_questions = [
  {
    answer: false,
    answers: { confirm_name: '' },
    questionId: '1',
    text: translate.CONFIRM_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { select_custom_name: '' },
    questionId: '2',
    text:
      process.env.REACT_APP_PARTNER === 'elephant'
        ? translate.WHAT_IS_YOUR_FIRST_LAST_NAME
        : translate.WHAT_IS_YOUR_VERIFY_NAME,
    title: '',
  },
  {
    answer: false,
    answers: { 'not_listed': `${translate.NOT_LISTED}` },
    questionId: '3',
    text: translate.CONFIRM_NUMBER_TEXT,
    title: '',
  },
  {
    answer: false,
    answers: { select_custom_number: 'Select Model' },
    questionId: '4',
    text: translate.SERVICE_NUMBER_UPDATE,
    title: '',
  },
  {
    answer: false,
    answers: { '5_YES': `${translate.YES}`, '5_NO': `${translate.NO}` },
    questionId: '5',
    text: translate.WANT_UPDATES,
    title: '',
  },
];

const title = translate.ERROR;

const msg = 'We are having trouble right now.\n\n Please try again.';

export function hasError(bool) {
  return {
    type: types.HAS_ERROR,
    hasError: bool,
  };
}

export function isLoading(bool) {
  return {
    type: types.IS_LOADING,
    isLoading: bool,
  };
}

export function fetchSuccessConfig(response) {
  return {
    type: types.GET_CONFIG,
    response,
  };
}

export function fetchSuccessConfigKeys(response) {
  return {
    type: types.GET_CONFIG_KEYS,
    response,
  };
}

export function fetchSuccessCacelReason(response) {
  return {
    type: types.GET_CANCEL_REASONS,
    response,
  };
}

export function getCancelReasons(partnerId) {
  var parser = require('ua-parser-js')();
  var v = APIConfigs.API_VERSION;
  var env = APIConfigs.ENVIROMENTUL;
  var industry = APIConfigs.INDUSTRY_TYPE;
  var device = parser.device.model;
  var os = parser.os.name;
  var app = APIConfigs.APP;

  VehicleAPI.cancelReasons.set(v, env, industry, device, os, app, partnerId);
  return (dispatch) => {
    // dispatch(isLoading(true));
    return UrgentlyAPIs.GET(VehicleAPI.cancelReasons.get)
      .then((response) => {
        if (response && response.length > 0) {
          dispatch(fetchSuccessCacelReason(response));
        }
        // dispatch(isLoading(false));
      })
      .catch(() => {
        dispatch(isLoading(false));
        let params = {};
        notification.notifyErrorAndTryAgain(
          title,
          message,
          getCancelReason,
          params
        );
      });
  };
}

export function emptyQA() {
  return (dispatch) => {
    dispatch({
      type: types.EMPTY_QA,
    });
  };
}

export function emptyHome() {
  return (dispatch) => {
    dispatch({
      type: types.EMPTY_HOME,
    });
  };
}

export function safecoEmptyQA() {
  return (dispatch) => {
    dispatch({
      type: types.SAFECO_EMPTY_QA,
    });
  };
}

export function setSelectedVehicle(vehicleInfo) {
  return (dispatch) => {
    dispatch({
      type: types.SELECTED_VEHICLE,
      vehicleInfo,
    });
  };
}

export function onResetQuestionTree(response) {
  return {
    type: types.RESET_QUESTION_TREE,
    response,
  };
}

export function selectProblem(response) {
  return (dispatch) => {
    dispatch({
      type: types.PROBLEM_SPECIFIED,
      response,
    });
  };
}

export function storeResponse(questionId, response) {
  return {
    type: types.STORE_RESPONSE,
    questionId,
    response,
  };
}

export function onSelectFinalServiceAction(finalService) {
  return (dispatch, getState) => {
    let vehicleDetermination = getState().vehicle;
    if (
      !vehicleDetermination.alreadyAddedVehicleNewVehicle &&
      vehicleDetermination.alreadyAddedVehicle
    ) {
      dispatch({
        type: types.ON_SELECT_FINAL_SERVICE_ACTION,
        response: finalService,
      });
    } else {
      if (
        vehicleDetermination.answers &&
        vehicleDetermination.answers[0].answerId
      ) {
        VehicleAPI.getVehicleMakes.type = vehicleDetermination.answers[0].answerId.toUpperCase();
        let rqURL = VehicleAPI.getVehicleMakes.get;
        dispatch(isLoading(true));
        return UrgentlyAPIs.GET(rqURL, false)
          .then((response) => {
            if (response.content && response.content.length > 0) {
              dispatch(setIsMakeDataAvailable(true));
            } else {
              dispatch(setIsMakeDataAvailable(false));
            }
            dispatch({
              type: types.ON_SELECT_FINAL_SERVICE_ACTION,
              response: finalService,
            });
            dispatch(isLoading(false));
          })
          .catch((error) => {
            dispatch(isLoading(false));
            swal({
              title: title,
              text: msg,
              showCancelButton: true,
              confirmButtonText: 'Try Again',
            }).then(
              (result) => {
                if (result) {
                  dispatch(onSelectFinalServiceAction(finalService));
                }
              },
              (dismiss) => {
                return false;
              }
            );
            //notification.notifyErrorAndTryAgain(title, msg, {onSelectFinalServiceAction}, finalService);
          });
      }
    }
  };
}

export const getQuestionForAnswer = (questionId, answerId) => {
  return (dispatch) => {
    QuestionAPI.getQuestions.setParam(questionId, answerId);
    let rqURL = QuestionAPI.getQuestions.getRQURL;

    dispatch(isLoading(true));
    return UrgentlyAPIs.GET(rqURL, false)
      .then((response) => {
        dispatch(onResetQuestionTree(response));
        dispatch(isLoading(false));
        if (response.answer) {
          dispatch(onSelectFinalServiceAction(true));
        }
      })
      .catch((error) => {
        dispatch(isLoading(false));
        dispatch(notification.postMessage(error));
      });
  };
};

export function reviseQuestion(questionIndex) {
  return (dispatch) => {
    dispatch(onSelectFinalServiceAction(false));
    dispatch({
      type: types.PREVIOUS_QUESTION,
      questionIndex,
    });
  };
}

export function onResetCustomQuestionTree(response) {
  return {
    type: types.RESET_CUSTOM_QUESTION_TREE,
    response,
  };
}
export function onResetPCustomQuestionTree(response) {
  return {
    type: types.RESET_PCUSTOM_QUESTION_TREE,
    response,
  };
}

export function onCustomerQuestionEnd(finalService) {
  return (dispatch, getState) => {
    let vehicleDetermination = getState().vehicle;
    dispatch({
      type: types.END_CUSTOM_QUESTION,
      response: finalService,
    });
    if (vehicleDetermination.flowOrigin == 'webAddVehicle') {
      dispatch(createVehicle());
    }
  };
}
export function onPCustomQuestionEnd(finalService) {
  return (dispatch, getState) => {
    let vehicleDetermination = getState().vehicle;
    dispatch({
      type: types.END_PCUSTOM_QUESTION,
      response: finalService,
    });
  };
}

export function storeCustomResponse(questionId, response) {
  return (dispatch) => {
    dispatch({
      type: types.STORE_CUSTOM_RESPONSE,
      questionId,
      response,
    });
    if (questionId < 4) {
      dispatch(getQuestionForCustomAnswer(questionId));
    } else {
      dispatch(onCustomerQuestionEnd(true));
    }
  };
}
export function storePCustomResponse(questionId, response) {
  return (dispatch) => {
    dispatch({
      type: types.STORE_PCUSTOM_RESPONSE,
      questionId,
      response,
    });
    if (process.env.REACT_APP_PARTNER === 'safeco'|| process.env.REACT_APP_PARTNER === 'bmwmini') {
      // For Safeco partner
      if (questionId < 6) {
        dispatch(getQuestionForPCustomAnswer(questionId));
      } else {
        dispatch(onPCustomQuestionEnd(true));
      }
    } else if (process.env.REACT_APP_PARTNER === 'amfam') {
      // For amfam partner
      if (questionId < 6) {
        // if (questionId == 4 && response.answerId == '4_NO') {
        //   dispatch(onPCustomQuestionEnd(true));
        // } else {
        // }
        dispatch(getQuestionForPCustomAnswer(questionId));
      } else {
        dispatch(onPCustomQuestionEnd(true));
      }
    } else {
      // For All partners except safeco
      if (questionId < 5) {
        // if (questionId == 5) { //&& response.answerId == '5_NO'
        //   dispatch(onPCustomQuestionEnd(true));
        // } else {
        // }
        dispatch(getQuestionForPCustomAnswer(questionId));
      } else {
        dispatch(onPCustomQuestionEnd(true));
      }
    }
  };
}
export function clearPCustomResponse() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_PCUSTOM_RESPONSE,
    });
  };
}

export const getQuestionForCustomAnswer = (questionId) => {
  return (dispatch) => {
    let nextQuestion;
    if (questionId) {
      nextQuestion = c_questions[questionId];
    } else {
      nextQuestion = c_questions[0];
    }
    dispatch(onResetCustomQuestionTree(nextQuestion));
  };
};

export const getQuestionForPCustomAnswer = (questionId) => {
  return (dispatch) => {
    let nextQuestion;
    if (
      process.env.REACT_APP_PARTNER === 'safeco' ||
      process.env.REACT_APP_PARTNER === 'bmwmini' ||
      process.env.REACT_APP_PARTNER === 'donlen' ||
      process.env.REACT_APP_PARTNER === 'amfam'
    ) {
      if (questionId) {
        console.log('questionId 618', questionId, nextQuestion );
        nextQuestion =
          (process.env.REACT_APP_PARTNER === 'safeco' 
          // || process.env.REACT_APP_PARTNER === 'bmwmini'
          )
            ? safeco_cp_questions[questionId]
            : amfam_cp_questions[questionId];
       if(process.env.REACT_APP_PARTNER === 'donlen'){
        nextQuestion = donlen_cp_questions[questionId]
       }

      } else {
        console.log('questionId 630', questionId, nextQuestion );
        nextQuestion =
          (process.env.REACT_APP_PARTNER === 'safeco' 
          // || process.env.REACT_APP_PARTNER === 'bmwmini'
          )
            ? safeco_cp_questions[0]
            : amfam_cp_questions[0];
            if(process.env.REACT_APP_PARTNER === 'donlen'){
              nextQuestion = donlen_cp_questions[questionId]
             }
      }
      dispatch(onResetPCustomQuestionTree(nextQuestion));
    } else {
      if (questionId) {
        nextQuestion = cp_questions[questionId];
      } else {
        nextQuestion = cp_questions[0];
      }
      dispatch(onResetPCustomQuestionTree(nextQuestion));
    }
  };
};

export function reviseCustomQuestion(questionIndex) {
  return (dispatch) => {
    dispatch({
      type: types.CUSTOM_PREVIOUS_QUESTION,
      questionIndex,
    });
  };
}

export const createVehicle = () => {
  return (dispatch, getState) => {
    let vehicleDetermination = getState().vehicle;

    let vehicleObj = {
      make: vehicleDetermination.c_answers[0].answerTitle,
      model: vehicleDetermination.c_answers[1].answerTitle,
      color: vehicleDetermination.c_answers[2].answerTitle,
      year: vehicleDetermination.c_answers[3].answerTitle,
      vehicleClass:
        vehicleDetermination.c_answers[1].vehicleClass ||
        vehicleClassEnum[
        vehicleDetermination.questions[
          vehicleDetermination.questions.length - 1
        ].questionId
        ],
      type:
        vehicleDetermination.c_answers[1].vehicleType ||
        vehicleDetermination.answers[0].answerId.toUpperCase(),
    };

    AccountsAPI.newVehicle.memberId = vehicleDetermination.memberId;
    let apiUrl = AccountsAPI.newVehicle.get;
    let vehicle = {
      make: vehicleObj.make,
      model: vehicleObj.model,
      color: vehicleObj.color,
      //licensePlate: vehicleObj.licensePlate,
      //vin: vehicleObj.vin,
      //state: vehicleObj.mobile,
      year: vehicleObj.year,
      vehicleClass: vehicleObj.vehicleClass,
      type: vehicleObj.type,
      default: true,
      //registeredBy: vehicleObj.registeredBy,
      //externalId: vehicleObj.externalId
      //transmission: vehicleObj.mobile,
    };
    dispatch(isLoading(true));
    return UrgentlyAPIs.POST(apiUrl, vehicle)
      .then((response) => {
        dispatch(vehicleCreatedSuccess(response));
        dispatch(isLoading(false));
      })
      .catch((error) => {
        dispatch(isLoading(false));
        dispatch(vehicleCreatedFailure(error));
      });
  };
};

export function vehicleCreatedSuccess(response) {
  return {
    type: types.VEHICLE_CREATED_SUCCESS,
    response,
  };
}

export function vehicleCreatedFailure(error) {
  return {
    type: types.VEHICLE_CREATION_FAILURE,
    error,
  };
}

export function onResetFaultQuestionTree(response) {
  return {
    type: types.RESET_FAULT_QUESTION_TREE,
    response,
  };
}

export function storeFaultResponse(questionId, response) {
  return {
    type: types.STORE_FAULT_RESPONSE,
    questionId,
    response,
  };
}

export const getQuestionForFaultAnswer = (
  questionId,
  answerId,
  vehicleClass = 'DEFAULT'
) => {
  return (dispatch) => {
    QuestionAPI.getQuestionsForAnswer.setParam(
      questionId,
      answerId,
      vehicleClass
    );
    let rqURL = QuestionAPI.getQuestionsForAnswer.getRQURL;

    dispatch(isLoading(true));
    return UrgentlyAPIs.GET(rqURL, false)
      .then((response) => {
        dispatch(onResetFaultQuestionTree(response));
        if (response.answer) {
          dispatch(onFaultOutcome(response));
        }
        dispatch(isLoading(false));
      })
      .catch((error) => {
        dispatch(isLoading(false));
        dispatch(notification.postMessage(error));
      });
  };
};

export const getQuestionForRootFaultAnswer = (questionId, answerId) => {
  return (dispatch) => {
    // QuestionAPI.getQuestionsForAnswer.setParam(questionId, answerId,vehicleClass);
    let params = QuestionAPI.getRootLevelQuestions.getparams;
    const language = params.lang;
    const product = params.product;
    const partner = params.partner;
    QuestionAPI.getQuestionsForRootAnswer.setParam(
      questionId,
      answerId,
      language,
      product,
      partner
    );
    let rqURL = QuestionAPI.getQuestionsForRootAnswer.getRQURL;

    dispatch(isLoading(true));
    return UrgentlyAPIs.GET(rqURL, false)
      .then((response) => {
        dispatch(onResetFaultQuestionTree(response));
        if (response.isLeaf) {
          dispatch(onFaultOutcome(response));
        }
        dispatch(isLoading(false));
      })
      .catch((error) => {
        dispatch(isLoading(false));
        dispatch(notification.postMessage(error));
      });
  };
};

export function reviseFaultQuestion(questionIndex) {
  return (dispatch) => {
    dispatch({
      type: types.FAULT_PREVIOUS_QUESTION,
      questionIndex,
    });
  };
}

export function onFaultOutcome(finalService) {
  return (dispatch) => {
    dispatch({
      type: types.ON_SERVICE_OUTCOME,
      response: finalService,
    });
  };
}
export function onSelectVehicleLocation(vlocation) {
  return {
    type: types.SELECTED_VEHICLE_LOCATION,
    payload: vlocation,
  };
}

export function onSelectDropOffLocation(dropLocation) {
  return {
    type: types.SELECTED_DROPPOFF_LOCATION,
    payload: dropLocation,
  };
}

export function setOtherVehicleLocation(value) {
  return {
    type: types.VEHICLE_OTHER_LOCATION,
    value,
  };
}

export function setOtherDropOffLocation(value) {
  return {
    type: types.DROP_OFF_OTHER_LOCATION,
    value,
  };
}

export function savePolicyDetail(value) {
  return {
    type: types.POLICY_INFO,
    value,
  };
}

export function getNearByDropOffLocations(rqURL, params, offset) {
  return (dispatch) => {
    dispatch(isLoading(true));
    return UrgentlyAPIs.POST(rqURL, params)
      .then((response) => {
        if (response && response.length > 0) {
          let locations = response.map((item, index) => {
            return {
              location: {
                latitude: item.latitude,
                longitude: item.longitude,
              },
              address: {
                fullAddress: item.address,
                providerName: item.providerName,
                distance: item.distance,
                street: item.street,
                city: item.city,
                statecode: item.state,
                zip: item.zip,
                id: item.id,
                order: item.order,
                type: item.type || null
              },
            };
          });
          if (offset) {
            dispatch(appendNearByDropOffLocations(locations));
          } else {
            dispatch(onNearByDropOffLocationsReceive(locations));
          }
          dispatch(isLoading(false));
          return locations;
        } else {
          dispatch(isLoading(false));
        }
      })
      .catch((error) => {
        dispatch(isLoading(false));
        dispatch(notification.postMessage(error));
      });
  };
}

export function onNearByDropOffLocationsReceive(locations) {
  return (dispatch) => {
    dispatch({
      type: types.NEAR_BY_DROP_OFF_LOCATIONS,
      response: locations,
    });
  };
}
export function appendNearByDropOffLocations(locations) {
  return (dispatch) => {
    dispatch({
      type: types.APPEND_NEAR_BY_DROP_OFF_LOCATIONS,
      response: locations,
    });
  };
}
export function onSetUserData(memberId, accountId, tokenExpiration) {
  return (dispatch) => {
    dispatch({
      type: types.SET_USER_DATA,
      memberId,
      accountId,
      tokenExpiration,
    });
  };
}

export function onConfirmDetailShow(bool) {
  return (dispatch) => {
    dispatch({
      type: types.CONFIRM_DETAIL_SHOW,
      response: bool,
    });
  };
}

export function onVehicleLocationSelected(bool) {
  return (dispatch) => {
    dispatch({
      type: types.VEHICLE_LOCATION_SELECTED,
      response: bool,
    });
  };
}

export function onDropOffLocationSelected(bool) {
  return (dispatch) => {
    dispatch({
      type: types.DROP_OFF_LOCATION_SELECTED,
      response: bool,
    });
  };
}

export function setFlowOrigin(from) {
  return (dispatch) => {
    dispatch({
      type: types.SET_FLOW_ORIGIN,
      response: from,
    });
  };
}

export function onSetPhone(phone) {
  return (dispatch) => {
    dispatch({
      type: types.SET_PHONE,
      response: phone,
    });
  };
}

export function onSetSupportNumber(phone) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CALL_SUPPORT,
      response: phone,
    });
  };
}

export function onMessageReceived(bool) {
  return (dispatch) => {
    dispatch({
      type: types.SET_MESSAGE_RECEIVED,
      response: bool,
    });
  };
}
export function onSetVin(vin) {
  return (dispatch) => {
    dispatch({
      type: types.SET_VIN_NUMBER,
      response: vin,
    });
  };
}
export function onSetCustomerType(customerType) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CUSTOMER_TYPE,
      response: customerType,
    });
  };
}
export function onSetPolicyStatus(policyStatus) {
  return (dispatch) => {
    dispatch({
      type: types.SET_POLICY_EXHAUSTED,
      response: policyStatus,
    });
  };
}
export function onSetOnDemandJob(onDemandJob) {
  return (dispatch) => {
    dispatch({
      type: types.SET_ON_DEMAND_JOB,
      response: onDemandJob,
    });
  };
}
export function onSetCampaign(campaign) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CAMPAIGN,
      response: campaign,
    });
  };
}

export function callDropOffLocation(bool) {
  return (dispatch) => {
    dispatch({
      type: types.CALL_DROP_OFF_LOCATION,
      response: bool,
    });
  };
}

export function onConfirmDetailTowJob(bool) {
  return (dispatch) => {
    dispatch({
      type: types.CONFIRM_DETAIL_TOW_JOB,
      response: bool,
    });
  };
}

export function onBackFromNative(boolean) {
  return (dispatch) => {
    dispatch({
      type: types.IS_BACK_FROM_NATIVE,
      response: boolean,
    });
  };
}
export function onSetAuthToken(authToken) {
  return (dispatch) => {
    dispatch({
      type: types.SET_AUTH_TOKEN,
      response: authToken,
    });
  };
}
export function onSetCustomerInformation(customerInfo) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CUSTOMER_INFO,
      response: customerInfo,
    });
  };
}
export function clearChatBot() {
  return (dispatch) => {
    dispatch({
      type: types.CLEAR_CHATBOT,
    });
  };
}
export function onSetVehicleData(vehicle) {
  return (dispatch) => {
    dispatch(vehicleCreatedSuccess(vehicle));
  };
}
export function setCurrentLocation(location) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CURRENT_LOCATION,
      response: location,
    });
  };
}
export function setIsMakeDataAvailable(response) {
  return (dispatch) => {
    dispatch({
      type: types.SET_IS_MAKE_DATA_AVAILABLE,
      response: response,
    });
  };
}
export function onAlreadyAddedVehicleSelect(boolean) {
  return (dispatch) => {
    dispatch({
      type: types.ALREADY_ADDED_VEHICLE_SELECT,
      response: boolean,
    });
  };
}
export function onAlreadyAddedVehicle(boolean) {
  return (dispatch) => {
    dispatch({
      type: types.ALREADY_ADDED_VEHICLE,
      response: boolean,
    });
  };
}

export function onAlreadyAddedVehicleNewVehicle(boolean) {
  return (dispatch) => {
    dispatch({
      type: types.ALREADY_ADDED_VEHICLE_NEW_VEHICLE,
      response: boolean,
    });
  };
}
export function revisePCustomQuestion(questionIndex) {
  return (dispatch) => {
    dispatch({
      type: types.PCUSTOM_PREVIOUS_QUESTION,
      questionIndex,
    });
  };
}
export function onSetConfigData(response) {
  return (dispatch) => {
    dispatch({
      type: types.SET_CONFIG_DATA,
      response: response,
    });
  };
}
export function onCustomerCreateJobSuccess(response) {
  return (dispatch) => {
    dispatch({
      type: types.CREATE_JOB_SUCCESS,
      payload: response,
    });
  };
}
export function setTokenDetails(response) {
  return (dispatch) => {
    dispatch({
      type: types.SET_TOKEN_DETAILS,
      response,
    });
  };
}

export function refreshToken() {
  return (dispatch, getState) => {
    dispatch(isLoading(true));
    let params = {
      accessToken: APIConfigs.AUTH_CODE,
      refreshToken: APIConfigs.REFRESH_TOKEN,
    };
    let memberId = getState().vehicle.memberId;
    let accountId = getState().vehicle.accountId;
    let rqURL = APIConfigs.AUTH_URL + LoginAPI.refreshToken.get;
    return UrgentlyAPIs.POST(rqURL, params, false)
      .then((response) => {
        if (response && response.accessToken) {
          APIConfigs.AUTH_CODE = response.accessToken;
          APIConfigs.REFRESH_TOKEN = response.refreshToken;
          let tokenExpiration =
            new Date().getTime() + response.expiresIn * 1000;
          dispatch(onSetUserData(memberId, accountId, tokenExpiration));
          dispatch(onSetAuthToken(response.accessToken));
          dispatch(onSetRefreshToken(response.refreshToken));
          dispatch(isLoading(false));
        }
      })
      .catch((error) => {
        dispatch(isLoading(false));
      });
  };
}

export function onSetRefreshToken(refreshToken) {
  return (dispatch) => {
    dispatch({
      type: types.SET_REFRESH_TOKEN,
      refreshToken,
    });
  };
}

export function onSetRefreshObject(refreshObj) {
  return (dispatch) => {
    dispatch({
      type: types.SET_AUTHORIZATION_OBJ,
      refreshObj,
    });
  };
}

export function onSetCurrentLocationAllowed(allowed) {
  return (dispatch) => {
    dispatch({
      type: types.IS_CURRENT_LOCATION_ALLOWED,
      allowed,
    });
  };
}

export function storePolicySearchInformation(searchData) {
  return (dispatch) => {
    dispatch({
      type: types.STORE_POLICY_SEARCH_INFO,
      searchData,
    });
  };
}

export function setIsNativeAppOrNot(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_IS_NATIVE_APP,
      data,
    });
  };
}

export function setOtherLocationForFlag(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_OTHER_LOCATION_FOR_FLAG,
      data,
    });
  };
}
export function storeFieldValue(data) {
  let allProps = {};
  data.forEach((obj, index) => {
    allProps[obj.key] = obj.value;
  });
  return (dispatch) => {
    dispatch({
      type: types.STORE_FIELD_VALUE,
      data: allProps,
    });
  };
}

export function setPartnerServiceToken(data) {
  return (dispatch) => {
    dispatch({
      type: types.SET_PARTNER_SERVICE_TOKEN,
      data,
    });
  };
}

export function odometerReading(data){
  return(dispatch) => {
    dispatch({
      type: types.ODOMETER_VALUE,
      data,
    })
  }
}

export function policyInformation_dvn(data){
  return(dispatch) => {
    dispatch({
      type: types.POLICY_INFORMATION,
      data
    })
  }
}

export function coverageCode(data){
  return(dispatch) => {
    dispatch({
      type: types.COVERAGE_CODE,
      data
    })
  }
}
