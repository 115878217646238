export const translations = {
  TRY_SHORTLY_TITLE: 'Veuillez réessayez plus tard',
  ERROR_TITLE: 'Oups!',
  CANCEL: 'Annuler',
  DETAILS: 'détails',
  NO: 'Non',
  ERROR: 'Erreur',
  WE_ARE_HAVING_TROUBLE_RIGHT_NOW:
    'Nous avons de la difficulté technique pour le moment. \n\n Veuillez réessayer.',
  TROUBLE_ERROR: 'Nous avons de la difficulté technique pour le moment.',
  TRY_AGAIN_NORMAL: 'Veuillez réessayer',
  TRY_AGAIN: 'Veuillez réessayer.',
  ENTER_LOCATION: 'Veuillez fournir votre emplacement',
  ANOTHER_LOCATION: 'Veuillez fournir un autre lieu',
  ENTER_ADDRESS: 'Veuillez fournir votre addresse',
  MORE: 'Voir plus',
  REQUEST_SERVICE: 'Demander du service',
  HELP: 'Aide',
  STIIL_HELP: `Nous ne pouvons pas trouver votre police. Nous pouvons encore vous aider, mais tous les coûts du service seront payés par vous.`,
  COVERAGE_EXHAUSTED: `Il semble que votre couverture actuelle est épuisée. Veuillez nous appeler pour vous assister.`,
  CONFIRM_LOCATION: `Veuillez confirmer l'emplacement de votre véhicule.`,
  SELECT_LOCATION: `Veuillez sélectionner l'emplacement de votre véhicule`,
  PERMISSION_DENIED: `Comme vous ne nous avez pas permis d'accéder à votre emplacement, il faudra que vous inscriviez cette adresse manuellement.`,
  EDIT: 'Modifier',
  REVISE: 'Réviser',
  SELECT_DROPOFF: `Veuillez sélectionner un lieu de dépôt`,
  SELECT_DROP_OFF: `Veuillez sélectionner un lieu de dépôt`,
  APPROX: 'Approximatif',
  MI: 'MI',
  TAP: 'Veuillez appuyer',
  TO_CALL: 'pour nous appeler',
  TAP_CALL_US: 'Veuillez appuyer pour nous appeler',
  ENTER_DROPOFF: 'Veuillez fournir un lieu de dépôt',
  OVERAGE_MESSAGE: `La distance du lieu de dépôt choisi dépasse la distance de l'installation de réparation la plus proche d'environ`,
  OVERAGE_CONTINUE: `kilomètres et des frais additionnels peuvent s'appliquer.`,
  AVOID_OVERAGE: `Pour éviter des frais additionnels, veuillez modifier le lieu de dépôt ou appuyez pour continuer`,
  CONTINUE: 'Continuer',
  CONFIRM_DETAILS: `D'accord! Confirmons donc les détails du service.`,
  CONFIRM_TEXT: 'Confirmer les détails du service.',
  NOT_LISTED: 'Pas inscrit',
  TOW_SERVICE: `D'accord. Il semble qu'on devrait remorquer votre véhicule.`,
  FIRST_NAME: 'Veuillez fournir votre prénom',
  LAST_NAME: 'Veuillez fournir votre nom de famille',
  NAME: 'Veuillez fournir le nom',
  FULL_NAME: 'Veuillez fournir votre prénom et nom de famille',
  ENTER_NUMBER: 'Entrez le numéro',
  ENTER_CALL_NUMBER: 'Quel est un bon numéro pour vous rappeler',
  YES: 'OUI',
  NO: 'NON',
  DATE: 'Veuillez fournir la date',
  TIME: `Veuillez fournir l'heure`,
  THANKS_FOR: 'Merci de nous informer sur votre',
  ADDED_TO_LIST: `C'est ajouté à votre liste de véhicule`,
  PICKUP_LOC: 'Lieu de remorquage',
  DESTINATION: 'La destination',
  CANCEL: 'Annuler',
  CURRENT_LOC: 'Lieu actuel',
  SEARCH: 'Chercher',
  CONFIRM_PICKUP: 'Confirmer lieu de remorquage',
  CONFIRM_DESTINATION: 'Confirmer destination',
  WHAT_IS_YOUR_FIRST_NAME: 'Excellent. Quel est votre prénom?',
  CONFIRM_NAME: "Veuillez confirmer votre nom",
  WHAT_IS_YOUR_FIRST_LAST_NAME:
    'Parfait. Veuillez fournir votre prénom et nom de famille.',
  WHAT_IS_YOUR_VERIFY_NAME: 'Génial! Pouvez-vous vérifier votre prénom et nom?',
  WHAT_IS_YOUR_LAST_NAME: 'Merci. Quel est votre nom de famille?',
  WHAT_IS_YOUR_CALLBACK_NUMBER: 'Quel est un bon numéro de rappel pour vous?',
  CONFIRM_NUMBER_TEXT: "Veuillez confirmer le numéro de cellulaire pour recevoir les mises à jour de votre requête de service",
  SERVICE_NUMBER_UPDATE: "Veuillez entrer le numéro de cellulaire pour les mises à jour de votre requête de service",
  NUMBER_NOT_LISTED: "Numéro non répertorié",
  IS_THIS_CELL_PHONE: "C'est un numéro de cellulaire?",
  WANT_UPDATES:
    'Voulez-vous recevoir des mises à jour et informations sur ce service par SMS?',
  TOW_LIMIT: 'Limite de remorquage excedée',
  TOW_HINT: `La distance de remorquage pour cette destination \n Excède la limite de {#} kilomètres. Nous pouvons toujours \naider, mais vous avez besoin de nous appeler.`,
  CALL_US: 'Appelez-nous',
  VEHICLE_LOCATION: 'Emplacement du véhicule',
  ENTER_VLOCATION: `Veuillez fournir l'emplacement du véhicule`,
  SELECT_PICKUP: 'Veuillez sélectionner un lieu de ramassage',
  TITLE: `Veuillez confirmer \n les détails e l'intervention \npour votre {{MAKE_MODAL}}`,
  VEHICLE_DESTINATION: 'Destination du véhicule',
  SELECT_DESTINATION: 'Sélectionner destination',
  DIST_HINT: `La distance de votre voyage est limitée à {{MAX_MILES}} milles.`,
  SELECT_DEALER: 'Veuillez sélectionner le lieu du concessionaire',
  CALLBACK_NAME: 'Nom de la personne à contacter',
  ENTER_NAME: `Veuillez fournir le nom de la personne pour le rappel`,
  NAME_HINT: 'Veuillez fournir le nom',
  VALID_NAME: 'Veuillez fournir un nom valide',
  ALLOW_ALPHA: `Usage uniquement d'alphabet`,
  SEND_TOW_UPDATES: `Envoyer des actualisations sur le remorquage par SMS.`,
  OPT_OUT_MSG: `Pour vous désabonner de messagerie par texte sur votre remorquage, veuillez désactiver cette option.`,
  REQ_TOW: 'Demander un remorquage',
  SELECT_DEST_LOC: `Veuilez sélectionner l'emplacement de la destination`,
  REPAIR_NUMBER: 'Numéro de la commande de réparation',
  ENTER_REPAIR_NUMBER:
    'Veuillez fournir le numéro de la commande de réparation',
  NUM_HINT: 'Veuillez fournir le numéro de la commande de réparation',
  VALID_NUM: 'Veuillez fournir un numéro valie de la commande de réparation',
  ALLOW_NUMERIC: 'Usage uniquement de valeur numérique',
  MAX_MILES: 'limite de milles de remorquage.',
  MIN_MILES: 'minimum de milles de remorquage.',
  LINK_EXPIRED: 'Lien expiré',
  PAGE_UNAVAILABLE: `Ce site n'est plus disponible.`,
  NEED_HELP: `Avez-vous encore besoin de l'aide?`,
  BMW_CENTER_SELECT: "Choisissez le centre BMW \noù vous avez pris votre rendez-vous pour les réparations.",
  SEARCH_PLACE_OR_ADDRESS: 'Chercher une adresse',
  WE_NEED_YOUR_PERMISSION: 'Nous avons besoin de votre permission pour vous localiser',
  UPDATE_BROWSER_SETTING: 'Veuillez mettre à jour les préférences de votre navigateur internet pour permettre votre localisation',
  MILES_AWAY: 'de milles de distance',
};
