export default {
  serviceRecord: {
    Signature: '/customer-accounts',
    accountId: null,
    set(accountId) {
      this.accountId = accountId;
    },
    get get() {
      return this.Signature + '/' + this.accountId + '/jobs';
    },
  },
  newCustomer: {
    Signature: '/customers',
    set() {},
    get get() {
      return this.Signature;
    },
  },
  addCustomer: {
    Signature: '/customers/signup?code=',
    code: null,
    set() {},
    get get() {
      return this.Signature + this.code;
    },
  },
  newVehicle: {
    Signature: '/customer-account-members',
    memberId: null,
    get get() {
      return this.Signature + '/' + this.memberId + '/vehicles';
    },
  },
  login: {
    Signature: '/customers/login',
    get get() {
      return this.Signature;
    },
  },
  updateName: {
    Signature: '/customer-account-members/',
    memberId: null,
    get get() {
      return this.Signature + this.memberId;
    },
  },
  updateNumber: {
    Signature: '/customer-account-members/',
    memberId: null,
    get get() {
      return this.Signature + this.memberId;
    },
  },
  customerLogin: {
    Signature: '/customers/login',
    get get() {
      return this.Signature;
    },
  },
};
