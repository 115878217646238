import * as types from '../../actionTypes';
import Immutable from 'seamless-immutable';

import initialState from './initialState';

const immutableInitialState = Immutable(initialState);

export default function reducer(state = immutableInitialState, action) {
  switch (action.type) {
    case types.SET_LOCATION_ADDRESS: {
      return {
        ...state,
        locationAddress: action.payload,
      };
    }
    case types.SELECT_DEFAULT_VEHICLE: {
      return {
        ...state,
        defaultVehicle: action.payload,
        vehicleLength: action.vehicleLength,
      };
    }
    case types.SET_AVAILABLE_PROVIDERS: {
      return {
        ...state,
        availableProviders: action.payload,
      };
    }
    case types.SAVE_NOTES: {
      return {
        ...state,
        notes: action.payload.notes,
      };
    }
    case types.IS_LOADING: {
      return {
        ...state,
        isLoading: action.isLoading,
      };
    }
    case types.PAYMENT_INFO: {
      return {
        ...state,
        paymentInfo: action.payload,
      };
    }
    case types.CREATE_JOB_SUCCESS:
      return {
        ...state,
        createJobSuccess: action.payload,
      };
    case types.EXCHANGE_LOCATION:
      return {
        ...state,
        exchangeLocation: action.payload,
      };
    case types.ON_THE_WAY_DETAILS:
      return {
        ...state,
        onTheWayDetails: action.payload,
      };
    case types.ETA_UPDATED_AT:
      return {
        ...state,
        etaUpdatedAt: action.payload,
      };
    case types.GOOGLE_ROUTE_DETAILS:
      return {
        ...state,
        routeDetails: action.payload,
      };
    case types.DRIVER_LOCATION_UPDATE:
      return {
        ...state,
        locationUpdates: action.payload,
      };
    case types.RESET_LOCATION_DATA:
      return {
        ...state,
        locationUpdates: [],
      };
    case types.AUTHORIZATION_INFO:
      return {
        ...state,
        authorizationInfo: action.payload,
      };
    case types.GET_CANCEL_REASONS: {
      return {
        ...state,
        cancelReason: action.response,
      };
    }
    case types.SET_PARTNER_CODE: {
      return {
        ...state,
        partnerCode: action.response,
      };
    }
    case types.COMPLIANCE_STATUS: {
      return {
        ...state,
        IsComplianceEnabled: action.response,
      };
    }
    case types.CONTACT_US_NUMBER:
      return {
        ...state,
        contactUS: action.payload,
      };
    case types.EMPTY_HOME: {
      return immutableInitialState;
    }
    case types.SET_PAYMENT_PARAMS: {
      return {
        ...state,
        paymentParams: action.response,
      };
    }
    case types.SET_JOB_INFO: {
      return {
        ...state,
        jobId: action.data.jobId,
        jobNumber: action.data.jobNumber,
        langCode: action.data.langCode,
      };
    }
    case types.GET_CONFIG_KEYS: {
      return {
        ...state,
        keys: action.response.data[0],
      };
    }
    case types.SET_REUNITE_JOB_INFO: {
      return {
        ...state,
        reUniteJobInfo: action.response,
      };
    }
    case types.SET_CASE_DETAIL: {
      return {
        ...state,
        caseDetail: action.response,
      };
    }
    default:
      return state;
  }
}
