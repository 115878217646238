/* global swal */
// import Swal from 'sweetalert2';

import translate from '../../shared/Localization/translate';

var notification = {
  postMessage: function (data) {
    window.postMessage(JSON.stringify(data), '*');
  },
  notifyError: function (title, message) {
    if (title != '') {
      swal(title, message);
    } else if (title == '') {
      swal('', message);
    }
  },
  notifyErrorAndRedirect: function (title, message, redirectUrl) {
    if (title != '') {
      swal(title, message).then(() => {
        window.location = redirectUrl;
      });
    } else if (title == '') {
      swal('', message).then(() => {
        window.location = redirectUrl;
      });
    }
  },
  notifyErrorAndTryAgain: function (title, message, callback, params) {
    if (title != '') {
      swal({
        title: title,
        text: message,
        showCancelButton: true,
        cancelButtonText: translate.CANCEL,
        confirmButtonText: translate.TRY_AGAIN_NORMAL,
      }).then(
        (result) => {
          if (result) {
            if (params && Array.isArray(params) && params.length > 1) {
              callback(...params);
            } else if (params) {
              callback(params);
            } else {
              callback();
            }
          }
        },
        (dismiss) => {
          return false;
        }
      );
    } else if (title == '') {
      swal({
        title: '',
        text: message,
        showCancelButton: true,
        cancelButtonText: translate.CANCEL,
        confirmButtonText: translate.TRY_AGAIN_NORMAL,
      }).then(
        (result) => {
          if (result) {
            callback();
          }
        },
        (dismiss) => {
          return false;
        }
      );
    }
  },
};

export default notification;
