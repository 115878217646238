export default {
  getJobDetail: {
    Signature: '/customer-accounts',
    accountId: null,
    jobNumber: null,

    set(accountId, jobNumber) {
      this.accountId = accountId;
      this.jobNumber = jobNumber;
    },

    get get() {
      return this.Signature + '/' + this.accountId + '/jobs/' + this.jobNumber;
    },
  },
  getReuniteJonInfo: {
    Signature: '/reunite-jobs/details/token/',
    code: null,

    set(code) {
      this.code = code;
    },

    get get() {
      return this.Signature + this.code;
    },
  },
  releaseOnHoldJob: {
    Signature: '/reunite-jobs/',
    job_no: null,

    set(no) {
      this.job_no = no;
    },

    get get() {
      return this.Signature + this.job_no + '/release-on-hold';
    },
  },
  checkCoverageByVehicle: {
    Signature: '/coverage/vehicle',
    make: null,
    model: null,
    vin: null,
    licensePlate: null,

    setParam(make, model, vin, licensePlate) {
      this.make = make;
      this.model = model;
      this.vin = vin;
      this.licensePlate = licensePlate;
    },

    get getParam() {
      return {
        make: this.make,
        model: this.model,
        vin: this.vin,
        licensePlate: this.licensePlate,
      };
    },

    get getRQURL() {
      return this.Signature;
    },
  },
  checkCoverageByVIN: {
    Signature: '/coverage/vin',
    vin: null,
    get getRQURL() {
      return this.Signature + '/' + this.vin;
    },
  },
  checkCoverageByRego: {
    Signature: '/coverage/license-plate',
    rego: null,
    get getRQURL() {
      return this.Signature + '/' + this.rego;
    },
  },
  authorizeBenefit: {
    Signature: '/coverage',
    decisionTreeOutcome: null,
    serviceType: null,
    customerId: null,
    customerPhone: null,

    setParam(customerId, customerPhone, decisionTreeOutcome, serviceType) {
      this.customerId = customerId;
      this.customerPhone = customerPhone;
      this.decisionTreeOutcome = decisionTreeOutcome;
      this.serviceType = serviceType;
    },

    get getParam() {
      return {
        customer: { phone: this.customerPhone },
        service: {
          decisionTreeOutcome: this.decisionTreeOutcome,
          serviceType: this.serviceType,
        },
      };
    },

    get getRQURL() {
      return this.Signature + '/' + this.customerId + '/authorize-benefit';
    },
  },
  createJob: {
    // /v4/customer-account-members/<memberID>/vehicles/<vehicleID>/jobs
    Signature: '/customer-account-members/',
    memberID: null,
    vehicleID: null,
    personalInfo: null, // its an object
    vehicle: null, // its an object
    service: null, // its an object
    location: null, // its an object
    dropOffLocation: null, // its an object

    setParam(memberID, vehicleID) {
      this.memberID = memberID;
      this.vehicleID = vehicleID;
    },
    setPersonalInfo(name, phone, phoneCode, isMobilePhone, device = '') {
      this.personalInfo = {
        name: name,
        phone: phone,
        phoneCode: phoneCode,
        isMobilePhone: isMobilePhone,
        device: device,
      };
    },
    setVehicleInfo(
      id,
      make,
      model,
      year,
      color,
      vin,
      licensePlate,
      externalId
    ) {
      this.vehicle = {
        id: id,
        make: make,
        model: model,
        year: year,
        color: color,
        vin: vin,
        licensePlate: licensePlate,
        externalId: externalId,
      };
    },
    setServiceInfo(serviceId, name, notes) {
      this.service = {
        serviceId: serviceId,
        name: name,
        notes: notes,
      };
    },
    setPickupLocation(
      street,
      city,
      state,
      zipCode,
      country,
      latitude,
      longitude,
      address
    ) {
      this.location = {
        street: street,
        city: city,
        state: state,
        zipCode: zipCode,
        country: country,
        latitude: latitude,
        longitude: longitude,
        address: address,
      };
    },
    setDropoffLocation(
      street,
      city,
      state,
      zipCode,
      country,
      latitude,
      longitude,
      address,
      facilityId
    ) {
      this.dropOffLocation = {
        externalFacilityId: facilityId,
        street: street,
        city: city,
        state: state,
        zipCode: zipCode,
        country: country,
        latitude: latitude,
        longitude: longitude,
        address: address,
      };
    },
    get getParam() {
      let params = {
        personalInfo: this.personalInfo,
        vehicle: this.vehicle,
        service: this.service,
        location: this.location,
      };
      if (this.dropOffLocation && this.dropOffLocation != 'undefined') {
        params['dropOffLocation'] = this.dropOffLocation;
      }
      return params;
    },

    get getRQURL() {
      return (
        this.Signature + this.memberID + '/vehicles/' + this.vehicleID + '/jobs'
      );
    },
  },
  getActiveJobs: {
    Signature: '/customer-accounts',
    accountId: null,

    setParam(accountId) {
      this.accountId = accountId;
    },

    get getRQURL() {
      return this.Signature + '/' + this.accountId + '/jobs?active=1';
    },
  },
  cancelJob: {
    Signature: '/customer-jobs',
    jobNumber: null,
    reasonId: null,
    notes: null,

    setParam(jobNumber, reasonId, notes) {
      this.jobNumber = jobNumber;
      this.reasonId = reasonId;
      if (notes) {
        this.notes = '&notes=' + notes;
      }
    },

    get getRQURL() {
      if (this.notes)
        return (
          this.Signature +
          '/' +
          this.jobNumber +
          '/cancel?reason=' +
          this.reasonId +
          this.notes
        );
      else {
        return (
          this.Signature +
          '/' +
          this.jobNumber +
          '/cancel?reason=' +
          this.reasonId
        );
      }
    },
  },
  getHistoryJobs: {
    Signature: '/customer-accounts',
    accountId: null,
    page: 0,
    size: 20,

    setParam(accountId, page = 0, size = 20) {
      this.accountId = accountId;
      this.page = page;
      this.size = size;
    },

    get getRQURL() {
      return (
        this.Signature +
        '/' +
        this.accountId +
        '/jobs?active=0&page=' +
        this.page +
        '&size=' +
        this.size
      );
    },
  },
  getCallUsNumber: {
    Signature: '/aga/call-us-number?clientId',
    clientId: null,
    get get() {
      return this.Signature + '=' + this.clientId;
    },
  },
  logCall: {
    Signature: '/aga/snapshot',
    get get() {
      return this.Signature;
    },
  },
  getPrices: {
    jobId: null,
    customertype: null,
    vehicle: null, // its an object
    service: null, // its an object
    location: null, // its an object
    dropOffLocation: null, // its an object
    policy: null,
    nearestFacility: null,
    Signature: '/estimates/price?ratetype=',
    setVehicleInfo(
      id,
      make,
      model,
      year,
      color,
      vin,
      licensePlate,
      externalId,
      vehicleClass
    ) {
      this.vehicle = {
        id: id,
        make: make,
        model: model,
        year: year,
        color: color,
        vin: vin,
        licensePlate: licensePlate,
        externalId: externalId,
        vehicleClass: vehicleClass,
      };
    },
    setServiceInfo(serviceId, name, notes) {
      this.service = {
        serviceId: serviceId,
      };
    },
    setPolicy(pNumber) {
      this.policy = {
        policyNumber: pNumber,
      };
    },
    setPickupLocation(
      street,
      city,
      state,
      zipCode,
      country,
      latitude,
      longitude,
      address
    ) {
      this.location = {
        street: street,
        city: city,
        state: state,
        zipCode: zipCode,
        country: country,
        latitude: latitude,
        longitude: longitude,
        address: address,
      };
    },
    setDropoffLocation(
      street,
      city,
      state,
      zipCode,
      country,
      latitude,
      longitude,
      address,
      facilityId,
      towMiles
    ) {
      this.dropOffLocation = {
        facilityId: facilityId,
        street: street,
        city: city,
        state: state,
        zipCode: zipCode,
        country: country,
        latitude: latitude,
        longitude: longitude,
        address: address,
        towMiles: towMiles,
        roadDistance: towMiles,
      };
    },
    setCustomerType(customerType) {
      this.customertype = customerType;
    },
    get getParam() {
      let params = {
        vehicle: this.vehicle,
        service: this.service,
        location: this.location,
      };
      if (this.policy && this.policy.policyNumber) {
        params['policy'] = this.policy;
      }
      if (this.dropOffLocation && this.dropOffLocation != 'undefined') {
        params['dropOffLocation'] = this.dropOffLocation;
      }
      if (this.nearestFacility && this.nearestFacility != 'undefined') {
        params['nearestFacility'] = this.nearestFacility;
      }
      return params;
    },
    get get() {
      return this.Signature + this.customertype;
    },
  },
  getNewPrice: {
    url: null,
    partnerCode: null,
    customer: null, // its an object
    policy: null,
    service: null, // its an object
    location: null, // its an object
    dropOffLocation: null, // its an object
    Signature: '/pricing-controller-v1/prices',
    setPartnerCode(partnerCode) {
      this.partnerCode = partnerCode;
    },
    setCustomerInfo(customer) {
      this.customer = customer;
    },
    setPolicy(policyInfo) {
      this.policy = policyInfo;
    },
    setServiceInfo(serviceInfo) {
      this.service = serviceInfo;
    },
    setURL(url) {
      this.url = url;
    },
    get getParam() {
      let params = {
        partnerCode: this.partnerCode,
        customer: this.customer,
        policy: this.policy,
        service: this.service,
      };
      return params;
    },
    get get() {
      return this.url + this.Signature;
    },
  },
  getDistance: {
    Signature: '/reunite-jobs/distance',
    get get() {
      return this.Signature;
    },
  },
  validateBenefit: {
    jobNumber: null,
    Signature: `/reunite-jobs/{{JOB_NUMBER}}/benefits`,
    get get() {
      return this.Signature.replace('{{JOB_NUMBER}}', this.jobNumber);
    },
    set setJobNumber(jobNumber) {
      this.jobNumber = jobNumber;
    },
  },
};
