import faultFr from './faultFr';

function getCurrentLanguage() {
  let lang = localStorage.getItem('language') || 'en';

  return lang;
}

const translations = {
  translation: (function () {
    if (localStorage.getItem('language') === 'fr') {
      return require('./languageFr').translations;
    } else {
      return require('./languageEn').translations;
    }
  })(),
};
export default translations.translation;

/**
 * translate "fault determination" node data from the literal string 
 * since it comes from the backend w/o keys [in answers].
 * 
 * return key if translation value isn't found
 */
export function tr(key) {
  let val = key;
  let lang = getCurrentLanguage();

  switch (lang) {
    case 'fr':
      val = faultFr[key];
  }

  if (val)
    return val;

  //return '[not found: ' + key + ']';
  return key;
}