import PubNub from 'pubnub';
import config from './constants/config';
import { messageCount, Listener, JobState } from './constants';

import { Events } from '../Utils/EventListener';

let connection;

const presenceSubscriptions = new Set();

// const messageSubscriptons = new Set();

const identifier = () => Math.random().toString(10).slice(12);

export const connect = () => {
  if (connection) {
    return connection;
  }

  connection = new Promise((resolve, reject) => {
    const uuid = identifier();
    const options = Object.assign({}, config.client, { uuid });
    const pubnub = new PubNub(options);

    const initialHandler = {
      status: (statusEvent) => {
        switch (statusEvent.category) {
          case 'PNConnectedCategory':
          case 'PNNetworkUpCategory':
            resolve(pubnub);
            break;
          case 'PNDisconnectedCategory':
          case 'PNNetworkDownCategory':
            reject(new Error('Received a network-down message'));
            break;
          default:
            return;
        }

        pubnub.removeListener(initialHandler);

        pubnub.addListener({
          message: function () {
            console.log('Pubnub: message received');
            if (arguments.length > 0) {
              let message = arguments[0].message;
              console.log(message.messageKey);
              let jobInfo = JSON.parse(message.message);
              console.log(jobInfo, 'number==', jobInfo.service.number);
              if (message.messageKey == 3034) {
                // proceedWithJobInfo(jobInfo.provider.status);
                Events.trigger(Listener.ProviderOnTheWay, jobInfo);
              } else if (message.messageKey == 3041) {
                Events.trigger(Listener.ETAchanged, jobInfo);
              } else if (message.messageKey === 'JOB_CHAT') {
                Events.trigger(Listener.VaChatMessageReceived, jobInfo);
              } 
              else {
                jobInfo['messageKey'] = message.messageKey;
                console.log('--------jobInfo Object---------');
                console.log(jobInfo);
                proceedWithJobInfo(jobInfo);
              }
              // Events.trigger(Listener.PubnubJobMessageReceived, jobInfo);
            }
            //messageSubscriptons.forEach(handler => handler.apply(undefined, arguments));
          },
          presence: function () {
            //  presenceSubscriptions.forEach(handler => handler.apply(undefined, arguments));
          },
          status: (statusEvent) => {
            switch (statusEvent.category) {
              case 'PNDisconnectedCategory':
              case 'PNNetworkDownCategory':
                connect(); // reconnect
                break;
            }
          },
        });
      },
    };

    pubnub.addListener(initialHandler);

    return handshake(pubnub)
      .then(() => resolve({ uuid, pubnub }))
      .catch(reject);
  });

  return connection;
};

export const disconnect = () => connect().then(({ pubnub }) => pubnub.stop());

const handshake = (pubnub) =>
  new Promise((resolve, reject) => {
    pubnub.time((status) => {
      if (status.error) {
        reject(
          new Error(
            `PubNub service failed to respond to time request: ${status.error}`
          )
        );
      } else {
        resolve(pubnub);
      }
    });
  });

export const subscribe = (channel, presenceHandler, messageHandler) => {
  // console.log("subscribe() channel: " + channel);

  // presenceSubscriptions.add(presenceHandler);
  // messageSubscriptons.add(messageHandler);
  connect().then(({ pubnub }) => {
    // console.log("pubnub.subscribe() ");
    pubnub.subscribe({
      channels: [channel],
      withPresence: false,
    });
  });

  return {
    unsubscribe: () => {
      // presenceSubscriptions.delete(presenceHandler);
      // messageSubscriptons.delete(messageHandler);

      return connect().then((handle) => handle.unsubscribe({ channel }));
    },
  };
};

// export const addPubnubListener = (messageHandler) => {
//   // Remove prevous handler
//   messageSubscriptons.add(messageHandler);
// }

export const proceedWithJobInfo = (jobInfo) => {
  switch (jobInfo.service.status) {
    case JobState.JOB_STATUS_NEW:
    case JobState.JOB_STATUS_CONTACTING_PROVIDERS:
    case JobState.JOB_STATUS_FOUND_PROVIDERS:
    case JobState.JOB_STATUS_RE_DISPATCH:
      Events.trigger(Listener.JobCreated, jobInfo);
      break;
    case JobState.JOB_STATUS_ASSIGNED:
      Events.trigger(Listener.JobAssigned, jobInfo);
      break;
    case JobState.JOB_STATUS_NOT_FOUND_PROVIDERS:
    case JobState.JOB_STATUS_EXPIRED:
    case JobState.JOB_STATUS_CLOSED_AS_EXPIRED:
    case JobState.JOB_STATUS_CLOSED_ALL_PROVIDER_REJECTED:
    case JobState.JOB_STATUS_CANCELED_BY_PROVIDER:
      // No Provider found, try shortly
      Events.trigger(Listener.NoProviderFound, jobInfo);
      break;
    case JobState.JOB_STATUS_COMPLETED_BY_PROVIDER_GOA:
    case JobState.JOB_STATUS_CLOSED_GOA_NO_PAYMENT:
      Events.trigger(Listener.JobCancelledByProvider, jobInfo);
      break;
    case JobState.JOB_STATUS_CANCELLED_BY_CUSTOMER:
    case JobState.JOB_STATUS_PROVIDER_CANCEL_BY_CONSUMER_REQUEST:
    case JobState.JOB_STATUS_CANCELED_BY_PROVIDER_FOR_CUSTOMER:
    case JobState.JOB_STATUS_CANCELLED_BY_CSR:
      Events.trigger(Listener.JobCancelledByCustomer, jobInfo);
      break;

    case JobState.JOB_STATUS_CLOSED_NO_PAYMENT_NEEDED:
    case JobState.JOB_STATUS_COMPLETE_BY_PROVIDER:
    case JobState.JOB_STATUS_CLOSED:
    case JobState.JOB_STATUS_CLOSED_PAID_BY_CASH:
    case JobState.JOB_STATUS_NO_CC_ON_FILE:
      //TODO:Show Receipt here
      Events.trigger(Listener.JobComplete, jobInfo);
      break;
    case JobState.JOB_STATUS_PAYMENT_FAILED:
      //TODO:
      // kCustomAlertWithParamAndTarget(ERROR_TITLE, PAYMENT_FAILED_MESSAGE, nil);
      Events.trigger(Listener.JobPaymentFailed, jobInfo);
      break;
    default:
    // console.log("Pubnub: message received: No Match found");
  }
};
