export default {
  getVehicleByVIN: {
    Signature: '/coverage/vin/',
    vin: '',

    set(vin) {
      this.vin = vin;
    },

    get get() {
      return this.Signature + this.vin;
    },
  },

  allVehicles: {
    Signature: '/customer-account-members/',
    memberId: '',

    setParam(memberId) {
      this.memberId = memberId;
    },
    get getRQURL() {
      return this.Signature + this.memberId + '/vehicles';
    },
  },

  getVehicleModel: {
    Signature: '/vehicles/models',
    make: '',

    set(make) {
      this.make = make;
    },
    get get() {
      return this.Signature + '?' + 'make' + '=' + this.make;
    },
  },

  getVehicleByRego: {
    Signature: '/coverage/license-plate/',
    rego: '',

    set(rego) {
      this.rego = rego;
    },

    get get() {
      return this.Signature + this.rego;
    },
  },

  setDefaultVehicle: {
    Signature: '/customer-account-members/',
    customerId: null,
    vehicleId: null,
    flag: true,

    setParam(customerId, vehicleId) {
      this.customerId = customerId;
      this.vehicleId = vehicleId;
    },

    get get() {
      return (
        this.Signature +
        this.customerId +
        '/vehicles/' +
        this.vehicleId +
        '/default?isdefault=' +
        this.flag
      );
    },
  },
  getVehicleMakes: {
    Signature: '/vehicle-configs/makes?limit=100',
    get get() {
      return this.Signature;
    },
  },
  getVehicleModels: {
    Signature: '/vehicle-configs/models',
    make: '',
    get get() {
      return this.Signature + '?make=' + this.make + '&limit=100';
    },
  },
  getVehicleColors: {
    Signature: '/vehicle-configs/colors?limit=50',
    get get() {
      return this.Signature;
    },
  },
  getVehicleYears: {
    Signature: '/vehicle-configs/years?limit=50',
    get get() {
      return this.Signature;
    },
  },
  getSystemConfig: {
    Signature: '/system-configs/keys?',
    configSignature: '/system-configs',
    support: '/support-phone-number?',
    env: '',
    apiVersion: '',
    partnerCode: '',

    setParam(env, apiVersion) {
      this.env = env;
      this.apiVersion = apiVersion;
    },
    setPartnerCode(code) {
      this.partnerCode = code;
    },
    get getRQURL() {
      if (
        process.env.REACT_APP_PARTNER === 'safeco' ||
        process.env.REACT_APP_PARTNER === 'bmwmini' ||
        process.env.REACT_APP_PARTNER === 'donlen' ||
        process.env.REACT_APP_PARTNER === 'elephant' ||
        process.env.REACT_APP_PARTNER === 'grange' ||
        process.env.REACT_APP_PARTNER === 'amfam' ||
        process.env.REACT_APP_PARTNER === 'bmw' ||
        process.env.REACT_APP_PARTNER === 'mbusa'
      ) {
        return (
          this.configSignature +
          this.support +
          'partnerCode=' +
          this.partnerCode
        );
      } else {
        return this.Signature + 'env=' + this.env + '&v=' + this.apiVersion;
      }
    },
  },
};
