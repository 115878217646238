export default {
  "What seems to be the problem with your vehicle?": "Qu'est-ce qui semble être le problème avec votre véhicule?",
  "Won't start": "Ne démarre pas",
  "Flat tire": "Crevaison",
  "Locked out": "Véhicule verrouillé",
  "Fuel Issues": "Problème d'essence",
  "Engine trouble": "Problème de moteur",
  "My problem isn't listed here": "Mon problème n'est pas dans cette liste",
  "I don't feel comfortable driving": "Je ne suis pas à l'aise de prendre le volant",
  "What seems to be the problem with your vehicle?": "Qu'est-ce qui semble être le problème avec votre véhicule?",
  "Won't start": "Ne démarre pas",
  "Have you recently tried to jump start your vehicle?": "Avez-vous récemment essayé de redémarrer le véhicule?",
  "Yes": "Oui",
  "No": "Non",
  "Do you have more than one flat tire?": "Avez-vous plusieurs pneus crevés?",
  "Where are the keys located?": "Où sont les clés?",
  "In the cabin": "Dans le vehicule",
  "In the trunk": "Dans le coffre arrière",
  "Not in the vehicle": "Pas dans le véhicule",
  "What type of fuel issue?": "Quel type de problème d'essence?",
  "Out of fuel": "Réservoir vide",
  "Wrong Fuel": "Mauvais type d'essence",
  "Fuel Leaking": "Fuite d'essence",
  "Ok. It seems we will need to tow your vehicle.": "D'accord. Il semble que votre véhicule a besoin d'être remorqué.",
  "Please call us on the number below to get help.": "Appelez-nous au numéro suivant.",
  "Ok. Let's set you up with a jump start.": "D'accord. Arrangeons un dépannage pour faire redémarrer votre véhicule.",
  "Do you have a good working spare in your vehicle?": "Avez-vous un bon pneu de rechange dans le véhicule?",
  "Ok. Let's set you up with a lockout service. Please call us on the number below to get help unlocking your vehicle.": "D'accord. Arrangeons un service pour déverrouiller votre véhicule. Appelez-nous au numéro suivant pour arranger votre intervention.",
  "Please call us on the number below to get help unlocking your vehicle.": "Appelez-nous au numéro suivant pour arranger l'ntervention pour déverrouiller votre véhicule.",
  "Ok. Let's set you up with fuel service. Please call us on the number below to arrange fuel delivery.": "D'accord. Arrangeons un service pour l'essence. Appelez-nous au numéro suivant pour arranger votre intervention.",
  "Ok. Let's set you up with a tire change.": "D'accord. Arrangeons un service pour changer votre pneu crevé.",
}
