export default {
  locationAddress: {},
  defaultVehicle: {},
  availableProviders: [],
  vehicleLength: 0,
  notes: '',
  partnerCode: null,
  createJobSuccess: null,
  isLoading: false,
  paymentInfo: {},
  authorizationInfo: {},
  cancelReason: [],
  locationUpdates: [],
  routeDetails: null,
  onTheWayDetails: null,
  etaUpdatedAt: null,
  contactUS: null,
  paymentParams: null,
  jobId: null,
  jobNumber: null,
  langCode: null,
  keys: {},
  reUniteJobInfo: null,
  exchangeLocation: null,
  caseDetail: null,
  IsComplianceEnabled: null
};
