import apiConfigs from '../../../../configs/AppConfigs';

export default {
  availableProviders: {
    Signature: '/provider-services/available-providers',
    industry: apiConfigs.INDUSTRY_TYPE,
    latitude: '',
    longitude: '',

    setParam(lat, long, industry = apiConfigs.INDUSTRY_TYPE) {
      this.latitude = lat;
      this.longitude = long;
      this.industry = industry;
    },

    // Set params & buld a API request URL. Ready to call.
    get getRQURL() {
      return (
        this.Signature +
        '?' +
        'longitude' +
        '=' +
        this.longitude +
        '&' +
        'latitude' +
        '=' +
        this.latitude +
        '&' +
        'industry' +
        '=' +
        this.industry
      );
    },
  },

  allServicesForIndustry: {
    Signature: '/available-services',
    industry: apiConfigs.INDUSTRY_TYPE,

    set(industry) {
      this.industry = industry;
    },
    get get() {
      return this.Signature + '?' + 'industry' + '=' + this.industry;
    },
  },
};
