export const translations = {
  TRY_SHORTLY_TITLE: 'Please Try Again Shortly',
  CANCELLED_TITLE: 'Job Cancelled',
  ERROR_TITLE: 'Oops!',
  CANCEL: 'Cancel',
  TRY_AGAIN_NORMAL: 'Try Again',
  NO: 'No',
  ERROR: 'Error',
  WE_ARE_HAVING_TROUBLE_RIGHT_NOW:
    'We are having trouble right now. \n\n Please try again.',
  TROUBLE_ERROR: 'We are having trouble right now.',
  TRY_AGAIN: 'Please try again.',
  ENTER_LOCATION: 'Enter location',
  ANOTHER_LOCATION: 'Enter another location',
  ENTER_ADDRESS: 'Enter Address',
  MORE: 'View more',
  REQUEST_SERVICE: 'Request Service',
  HELP: 'Help',
  STIIL_HELP: `We’re unable to find your policy. We can still provide help, but all service costs will need to be paid for out-of- pocket.`,
  COVERAGE_EXHAUSTED: `It looks like your current coverage is exhausted. We can still provide help, but we will need you to give us a call.`,
  CONFIRM_LOCATION: `Please confirm your vehicle’s location.`,
  SELECT_LOCATION: `Please select your vehicle's location.`,
  PERMISSION_DENIED: `Since you did not allow us to use your current location, we will need you to input the address manually.`,
  EDIT: 'EDIT',
  REVISE: 'REVISE',
  SELECT_DROPOFF: `Please select a dropoff location.`,
  SELECT_DROP_OFF: `Please select a drop-off location.`,
  APPROX: 'APPROX',
  MI: 'MI',
  TAP: 'Please tap',
  TO_CALL: 'to call us',
  TAP_CALL_US: 'Please tap to call us',
  ENTER_DROPOFF: 'Enter Drop-Off Location',
  OVERAGE_MESSAGE: `The distance to the selected drop-off location exceeds the nearest qualified facility by approximately`,
  OVERAGE_CONTINUE: `miles and charges may apply.`,
  AVOID_OVERAGE: `To avoid charges, please edit the vehicle drop-off location or tap to continue.`,
  CONTINUE: 'Continue',
  CONFIRM_DETAILS: 'Okay! Lets’ confirm the service details',
  CONFIRM_TEXT: 'Confirm Service Details',
  NOT_LISTED: 'Not Listed',
  TOW_SERVICE: 'OK.It seems we will need to tow your vehicle.',
  FIRST_NAME: 'Enter First Name',
  LAST_NAME: 'Enter Last Name',
  NAME: 'Enter Name',
  FULL_NAME: 'Enter First and Last Name',
  ENTER_NUMBER: 'Enter Number',
  ENTER_CALL_NUMBER: 'Enter Callback Number',
  YES: 'YES',
  NO: 'NO',
  DATE: 'Enter the date',
  TIME: 'Enter the time',
  THANKS_FOR: 'Thanks for telling me about your',
  ADDED_TO_LIST: `It’s been added to your vehicle list.`,
  PICKUP_LOC: 'Pick-up Location',
  DESTINATION: 'Destination',
  CURRENT_LOC: 'Current Location',
  SEARCH: 'Search',
  CONFIRM_PICKUP: 'Confirm Pick-up',
  CONFIRM_DESTINATION: 'Confirm Destination',
  WHAT_IS_YOUR_FIRST_NAME: "Great. What's your first name?",
  CONFIRM_NAME: "Please tap to confirm your name",
  WHAT_IS_YOUR_FIRST_LAST_NAME: 'Great. Please enter your first and last name.',
  WHAT_IS_YOUR_VERIFY_NAME: 'Great! Can you verify your name?',
  WHAT_IS_YOUR_LAST_NAME: "Thanks. What's your last name?",
  WHAT_IS_YOUR_CALLBACK_NUMBER: 'What is a good callback number for you?',
  CONFIRM_NUMBER_TEXT: "Please confirm the callback cellphone number to receive service updates",
  SERVICE_NUMBER_UPDATE: "Please enter the cell phone number for service updates",
  NUMBER_NOT_LISTED: "Number not listed",
  IS_THIS_CELL_PHONE: 'Is this a cell phone?',
  WANT_UPDATES:
    'Would you like us to send updates and information about this job via text?',
  TOW_LIMIT: 'Tow Limit Exceeded',
  TOW_HINT: `The towing distance to this destination \nexceeds the {#} mile limit. We can still \nhelp, but will need you to call us.`,
  CALL_US: 'Call Us',
  VEHICLE_LOCATION: 'Vehicle Location',
  ENTER_VLOCATION: 'Enter Vehicle Location',
  SELECT_PICKUP: 'Please select Pick-up Location',
  TITLE: `Please confirm the details of\n your service request for your\n {{MAKE_MODAL}}.`,
  VEHICLE_DESTINATION: 'Vehicle Destination',
  SELECT_DESTINATION: 'Select Destination',
  DIST_HINT: `Your trip distance is limited to {{MAX_MILES}} miles.`,
  SELECT_DEALER: 'Please select Dealer Location',
  CALLBACK_NAME: 'Callback Name',
  ENTER_NAME: `Enter Callback Name`,
  NAME_HINT: 'Please enter name',
  VALID_NAME: 'Please enter valid name',
  ALLOW_ALPHA: `Allowed only alphabets`,
  SEND_TOW_UPDATES: `Send tow updates via text.`,
  OPT_OUT_MSG: `To opt out of receiving text updates for your tow, please uncheck.`,
  REQ_TOW: 'Request Tow',
  SELECT_DEST_LOC: 'Please select Destination Location',
  REPAIR_NUMBER: 'Repair Order Number',
  ENTER_REPAIR_NUMBER: 'Enter Repair Order Number',
  NUM_HINT: 'Please enter repair order number',
  VALID_NUM: 'Please enter valid repair order number',
  ALLOW_NUMERIC: 'Allowed only numeric value',
  MAX_MILES: 'maximum tow miles.',
  MIN_MILES: 'minimum tow miles.',
  LINK_EXPIRED: 'Link Expired',
  PAGE_UNAVAILABLE: 'This page is no longer available.',
  NEED_HELP: 'Still Need Help?',
  BMW_CENTER_SELECT: `Select the BMW Center \nwhere you scheduled your repair.`,
  MBUSA_CENTER_SELECT: `Select the MBUSA Center \nwhere you scheduled your repair.`,
  SEARCH_PLACE_OR_ADDRESS: 'Search for a place or address',
  WE_NEED_YOUR_PERMISSION: 'We need your permission to use your location.',
  UPDATE_BROWSER_SETTING: 'Please update your browser settings allow access to your location.',
  MILES_AWAY: 'miles away',
};
