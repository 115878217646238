export default {
  config: {
    Signature: '/configs',
    v: '',
    env: '',
    industry: '',
    device: '',
    os: '',
    app: '',

    set(v, env, industry, device, os, app) {
      this.v = v;
      this.env = env;
      this.industry = industry;
      this.device = device;
      this.os = os;
      this.app = app;
    },

    get get() {
      return (
        this.Signature +
        '?' +
        'v' +
        '=' +
        this.v +
        '&' +
        'env' +
        '=' +
        this.env +
        '&' +
        'industry' +
        '=' +
        this.industry +
        '&' +
        'device' +
        '=' +
        this.device +
        '&' +
        'os' +
        '=' +
        this.os +
        '&' +
        'app' +
        '=' +
        this.app
      );
    },
  },

  configKey: {
    Signature: '/configs/keys',
    v: '',
    env: '',
    industry: '',
    device: '',
    os: '',
    app: '',

    set(v, env, industry, device, os, app) {
      this.v = v;
      this.env = env;
      this.industry = industry;
      this.device = device;
      this.os = os;
      this.app = app;
    },

    get get() {
      return (
        this.Signature +
        '?' +
        'v' +
        '=' +
        this.v +
        '&' +
        'env' +
        '=' +
        this.env +
        '&' +
        'industry' +
        '=' +
        this.industry +
        '&' +
        'device' +
        '=' +
        this.device +
        '&' +
        'os' +
        '=' +
        this.os +
        '&' +
        'app' +
        '=' +
        this.app
      );
    },
  },

  cancelReasons: {
    Signature: '/configs/customer/cancel/reasons',
    v: '',
    env: '',
    industry: '',
    device: '',
    os: '',
    app: '',

    set(v, env, industry, device, os, app) {
      this.v = v;
      this.env = env;
      this.industry = industry;
      this.device = device;
      this.os = os;
      this.app = app;
    },

    get get() {
      return (
        this.Signature +
        '?' +
        'v' +
        '=' +
        this.v +
        '&' +
        'env' +
        '=' +
        this.env +
        '&' +
        'industry' +
        '=' +
        this.industry +
        '&' +
        'device' +
        '=' +
        this.device +
        '&' +
        'os' +
        '=' +
        this.os +
        '&' +
        'app' +
        '=' +
        this.app
      );
    },
  },

  partners: {
    Signature: '/customer/configs/partners',

    get get() {
      return this.Signature;
    },
  },
};
