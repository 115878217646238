const { REACT_APP_PARTNER_CODE_IN_DEALER_LOCATION, REACT_APP_DEALER_RADIUS, REACT_APP_INITIAL_DEALER_LIMIT, REACT_APP_LOAD_MORE_DEALER_LIMIT, REACT_APP_PARTNERCODE } = process.env;
export default {
  // https://dev02-apis.allianzau.urgent.ly/v3/fault-detection/questions?vehicleType=MOTORCYCLE
  getInitialQuestions: {
    Signature: '/fault-detection/questions?vehicleType=',
    vehicleType: 'DEFAULT',

    setParam(vehicleType = 'DEFAULT') {
      this.vehicleType = vehicleType;
    },
    get getRQURL() {
      return this.Signature + this.vehicleType;
    },
  },

  // https://dev02-apis.urgent.ly/v4/service-determination/root?language=en&version=1&productCode=oncallapp&partnerCode=volvo
  getRootLevelQuestions: {
    Signature: '/service-determination/root?language=',
    language: 'en',
    productCode: 'oncallapp',
    partnerCode: 'volvo',

    setLanguage(language) {
      this.language = language;
    },
    setProductCode(code) {
      this.productCode = code;
    },
    setPartnerCode(code) {
      this.partnerCode = code;
    },

    get getparams() {
      return {
        lang: this.language,
        product: this.productCode,
        partner: this.partnerCode,
      };
    },

    get getRQURL() {
      return (
        this.Signature +
        this.language +
        '&version=1&productCode=' +
        this.productCode +
        '&partnerCode=' +
        this.partnerCode
      );
    },
  },

  // https://dev02-apis.allianzau.urgent.ly/v3/fault-detection/questions?questionId=2&answer=a2
  getQuestionsForAnswer: {
    Signature: '/fault-detection/questions?',
    qID: '',
    ansID: '',
    vehicleType: 'DEFAULT',
    setParam(qID, ansID, vehicleType = 'DEFAULT') {
      this.qID = qID;
      this.ansID = ansID;
      this.vehicleType = vehicleType;
    },
    get getRQURL() {
      return (
        this.Signature +
        'questionId=' +
        this.qID +
        '&answer=' +
        this.ansID +
        '&vehicleType=' +
        this.vehicleType
      );
    },
  },

  getQuestionsForRootAnswer: {
    Signature: '/service-determination/',
    qID: '',
    ansID: '',
    language: '',
    productCode: '',
    partnerCode: '',

    setParam(qID, ansID, lang, product, partner) {
      this.qID = qID;
      this.ansID = ansID;
      this.language = lang;
      this.productCode = product;
      this.partnerCode = partner;
    },
    get getRQURL() {
      let remUrl =
        this.partnerCode +
        '_' +
        this.productCode +
        '_' +
        this.language +
        '_v1/nodes/';
      return this.Signature + remUrl + this.ansID;
    },
  },

  getNearbyDropOffLocations: {
    partnerCode: '',
    offset: 0,
    limit: REACT_APP_INITIAL_DEALER_LIMIT,
    radius: REACT_APP_DEALER_RADIUS,
    Signature: `/customer-location-dropoffs`,
    street: '',
    city: '',
    state: '',
    zip: '',
    latitude: '',
    longitude: '',
    locationType: (process.env.REACT_APP_PARTNER == 'volvo' ||
      process.env.REACT_APP_PARTNER == 'bmw' ||
      process.env.REACT_APP_PARTNER === 'mbusa') ? 'DEALER' : '',
    setParam(street, city, state, zip, latitude, longitude, partnerCode) {
      this.street = street;
      this.city = city;
      this.state = state;
      this.zip = zip;
      this.latitude = latitude;
      this.longitude = longitude;
      this.partnerCode = partnerCode;
    },
    get getParam() {
      return {
        street: this.street,
        city: this.city,
        state: this.state,
        zip: this.zip,
        latitude: this.latitude,
        longitude: this.longitude,
        locationType: this.locationType,
      };
    },
    get getRQURL() {
      return `${this.Signature}?radius=${this.radius}&offset=${this.offset}&limit=${this.limit}${REACT_APP_PARTNER_CODE_IN_DEALER_LOCATION === 'true' ? `&partnerCode=${REACT_APP_PARTNERCODE}` : ''}`
    },
    setOffsetLimit(offset) {
      this.limit = offset > 0 ? REACT_APP_LOAD_MORE_DEALER_LIMIT : REACT_APP_INITIAL_DEALER_LIMIT;
      this.offset = offset;
    },
    setRadius(radius) {
      this.radius = radius;
    }
  },
  getQuestions: {
    Signature: '/vehicles/determine-class',
    qid: '',
    answer: '',
    setParam(qid, answer) {
      this.qid = qid;
      this.answer = answer;
    },
    get getRQURL() {
      if (this.qid && this.answer) {
        return (
          this.Signature + '?questionId=' + this.qid + '&answer=' + this.answer
        );
      } else {
        return this.Signature;
      }
    },
  },
};
